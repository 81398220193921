<template>
  <v-app>
    <v-container fluid>
      <v-row justify="center" >
            <v-col align="center">
                <v-card class="ml-2" width="600">
                    <span class="text-sm-h4 font-weight-bold">請掃描身分證條碼或個人QRcode</span><br>
                    <StreamBarcodeReader
                        @decode="onDecode"
                        class="mirrorRotateLevel"
                    ></StreamBarcodeReader>
                    <br/>
                </v-card>                            
            </v-col>
        </v-row>
    <br/>
    </v-container>
  </v-app>
</template>

<script>
import commonMixin from '@/utils/commonMixin';
import { StreamBarcodeReader } from "vue-barcode-reader";
import {checkIn} from "@/api/demoApi";

export default {
  name: 'barcodeInout',
  mixins: [commonMixin],
  created(){
    this.auth = {
      headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getTokenWithSession(),
      }
    };
  },
  destroyed() {
    
  },
  mounted(){
    
  },
  methods: {
    onDecode: function(result) {   
        var vm = this;
        if (vm.scanCheck) {
            vm.scanCheck = false;
            console.log(" checkNoId : " + vm.checkNoId(result));
            if (vm.checkNoId(result)) {
              var data = new Object();
              data.siteId = vm.siteId;
              data.idno = result;
              vm.checkInSend(data);
            } else {
              var checkJson = true;
              try {
                var personalData = JSON.parse(result);
              } catch (error) {
                checkJson = false;
              }

              if (checkJson) {
                personalData.siteId = vm.siteId;
                console.log("personalData = " + JSON.stringify(personalData));
                vm.checkInSend(personalData);
                //vm.scanCheck = true;
              } else {
                console.log("non ID : " + result);
                vm.scanCheck = true;
              }

            }
        }
    },
    checkNoId: function(userIdNo) {
      if (typeof userIdNo == "undefined")
        userIdNo = "";

      if (userIdNo.length < 10) {
        return false;
      }

      userIdNo = userIdNo.toUpperCase();
      // 依照字母的編號排列。
      var idHeader = "ABCDEFGHJKLMNPQRSTUVXYWZIO";
      // 儲存各個乘數
      var multiply = new Array(1, 9, 8, 7, 6, 5, 4, 3, 2, 1);
      var nums = new Array(3);
      var firstChar;
      var firstNum;
      var lastNum;
      var total = 0;

      // 撰寫「正規表達式」。第一個字為英文字母，
      var regExpID0=/^[a-z](1|2|8|9)\d{8}$/i; //台灣人第二個字為1或2，新居留證號第二個字為8或9，後面跟著8個數字，不分大小寫。
      var regExpID1=/^[a-z]([a-d])\d{8}$/i; //外國人第二個字為ABCD，後面跟著7個數字，不分大小寫。
      // 使用「正規表達式」檢驗格式
      if (userIdNo.search(regExpID0)!=-1) {
        nums[2] = parseInt(userIdNo.charAt(1));
        //return false || "本國人";
      } else if (userIdNo.search(regExpID1)!=-1) {
        nums[2] = idHeader.indexOf(userIdNo.charAt(1)) % 10;
        //console.log(nums[2]);
        //return false || "外國人";
      } else {
        return false;
      }
      firstChar = userIdNo.charAt(0).toUpperCase();
      lastNum = userIdNo.charAt(9);

      // 找出第一個字母對應的數字，並轉換成兩位數數字。
      firstNum = idHeader.indexOf(firstChar) + 10;
      nums[0] = Math.floor(firstNum / 10);
      nums[1] = firstNum - (nums[0] * 10);

      // 執行加總計算
      for(var i=0; i<multiply.length; i++){
        if (i<3) {
          total += nums[i] * multiply[i];
        } else {
          total += parseInt(userIdNo.charAt(i-1)) * multiply[i];
        }
      }

     total =  total + parseInt(lastNum);
      // 和最後一個數字比對
      if ((total % 10) != 0) {
        return false;
      } 
      return true;
    },
    idMask: function(str, start, length){
        var returnValue = "";
         [... str].forEach((item, index) => {
            if (index >= (start - 1) && index < (start + length - 1)) {
                returnValue += "*";
            } else {
                returnValue += item;
            }
        });

        return returnValue;
    },
    checkInSend: function(data) {
      var vm = this;
      checkIn(data, vm.auth).then(function(response) {
        if ("200" == response.status) { 
          var responseData = response.data;

          if (responseData.statusCode === "1") {
            var userData = responseData.userData;
            vm.$swal({
              title: `<span style="color:green;font-size:40px;">登記成功</span>`,
              html: `<svg style="width:100px;height:100px;color: green;" viewBox="0 0 24 24">
                <path fill="currentColor" d="M12,17.5C14.33,17.5 16.3,16.04 17.11,14H6.89C7.69,16.04 9.67,17.5 12,17.5M8.5,11A1.5,1.5 0 0,0 10,9.5A1.5,1.5 0 0,0 8.5,8A1.5,1.5 0 0,0 7,9.5A1.5,1.5 0 0,0 8.5,11M15.5,11A1.5,1.5 0 0,0 17,9.5A1.5,1.5 0 0,0 15.5,8A1.5,1.5 0 0,0 14,9.5A1.5,1.5 0 0,0 15.5,11M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
              </svg>
              <div style="font-size:30px;font-weight:bold;">`+ userData.idno +`</div>
              <div style="font-size:30px;font-weight:bold;">`+ userData.name +`</div>
              <div style="font-size:30px;font-weight:bold;">`+ userData.phone +`</div>`,
              timer: 3000,
              showConfirmButton: false
            });
            
            setTimeout(() => {
              vm.scanCheck = true
            }, 3500);
          } else if (responseData.statusCode === "2") {
            vm.$swal({
              position: 'top',
              title: `<span style="color:red;font-size:40px;">請輸入基本資料</span>`,
              html: `
              <s style="width:100px;height:100px;color: #c00;" viewBox="0 0 24 24" v-if="isPass === 'N'">
              <path fill="currentColor" d="M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M15.5,8C16.3,8 17,8.7 17,9.5C17,10.3 16.3,11 15.5,11C14.7,11 14,10.3 14,9.5C14,8.7 14.7,8 15.5,8M10,9.5C10,10.3 9.3,11 8.5,11C7.7,11 7,10.3 7,9.5C7,8.7 7.7,8 8.5,8C9.3,8 10,8.7 10,9.5M12,14C13.75,14 15.29,14.72 16.19,15.81L14.77,17.23C14.32,16.5 13.25,16 12,16C10.75,16 9.68,16.5 9.23,17.23L7.81,15.81C8.71,14.72 10.25,14 12,14Z" tabindex="0"/>
              </s>
              <input type="text" class="swal2-input" style="font-size:30px;font-weight:bold;margin-bottom: 0px;" placeholder="身份證字號" readonly value="` + vm.idMask(data.idno,4,4) + `">
              <input type="hidden" id="idno" value="` + data.idno + `">
              <input type="text" id="name" class="swal2-input" style="font-size:30px;font-weight:bold;margin-bottom: 0px;" placeholder="姓名" >
              <input type="text" id="phone" class="swal2-input" style="font-size:30px;font-weight:bold;" placeholder="電話">
              `,
              showCancelButton: true,
              confirmButtonText: "<font style='font-size: 30px;font-weight: bolder;'>送出</font>",
              cancelButtonText: "<font color='#fff' style='font-size: 30px;font-weight: bolder;'>離開</font>",
              timer: 60000,
              focusConfirm: false,
              preConfirm: () => {
                  const idno = vm.$swal.getPopup().querySelector('#idno').value
                  const name = vm.$swal.getPopup().querySelector('#name').value
                  const phone = vm.$swal.getPopup().querySelector('#phone').value
                  if (!name || !phone) {
                      vm.$swal.showValidationMessage(`請輸入姓名和電話`)
                  }

                  return { idno: idno, name: name, phone: phone }
              },
            }).then((result) => {
              console.log(result);
              if (result.isConfirmed) {
                  var data = new Object();
                  data.siteId = vm.siteId;
                  data.idno = result.value.idno;
                  data.name = result.value.name;
                  data.phone = result.value.phone;
                  vm.checkInSend(data);
              } else { 
                  setTimeout(() => {
                      vm.scanCheck = true;
                  }, 500);
              }
            });
          } else if (responseData.statusCode === "0"){
            vm.$swal(responseData.message);
            vm.scanCheck = true;
          }

        } else {
          vm.$swal("服務錯誤，請再試一次。");
        }
      });
    },

  },
  watch: {
    
  },
  data: () => ({
    auth: {},
    scanCheck: true,
    siteId: "demoSite",
  }),
  components: {
    StreamBarcodeReader,
  },
}
</script>

<style scoped>

  .mirrorRotateLevel {          /* 水平镜像翻转 */
      -moz-transform:scaleX(-1);
      -webkit-transform:scaleX(-1);
      -o-transform:scaleX(-1);
      transform:scaleX(-1);
      /*兼容IE*/
      filter:FlipH;
  }
  .mirrorRotateVertical {        /* 垂直镜像翻转 */
      -moz-transform:scaleY(-1);
      -webkit-transform:scaleY(-1);
      -o-transform:scaleY(-1);
      transform:scaleY(-1);
      /*兼容IE*/
      filter:FlipV;
  }

</style>